<template>
  <div class="job-responsibilities">
    <h4 class="job-responsibilities__title">Мои задачи</h4>
    <JobResponsibilitiesItems :responsibilities="responsibilities" />
  </div>
</template>

<script>
const JobResponsibilitiesItems = () =>
  import(
    "@/components/ProfileComponents/JobFolderComponents/JobResponsibilities/JobResponsibilitiesItems"
  );

export default {
  name: "JobResponsibilities",
  mixins: [],
  props: {
    responsibilities: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { JobResponsibilitiesItems },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";

.job-responsibilities {
  display: flex;
  flex-direction: column;

  &__title {
    margin: 0 0 35px 0;
    padding: 0;

    line-height: 20px;
    font-size: 16px;
    color: $color-text-accent;
    font-weight: 500;
  }
}
</style>
